import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RotasEntity, interfRotas } from '../../entitys/rotas.entity';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AbstractInsertEdit,
  HttpClient,
  InsertEditConfig,
} from '@datagrupo/dg-crud';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpBackend } from '@angular/common/http';

interface retornoViaCep {
  logradouro: string;
  bairro: string;
  cidade: string;
  uf: string;
  localidade: string;
  erro: boolean;
}

@Component({
  selector: 'app-rotas-inser-edit',
  templateUrl: './rotas-inser-edit.component.html',
  styleUrls: ['./rotas-inser-edit.component.scss'],
})
export class RotasInserEditComponent
  extends AbstractInsertEdit<RotasEntity>
  implements OnInit
{
  @ViewChild('closeModal') closeModal!: ElementRef;

  @ViewChild('modalParada') modalParada!: ElementRef;

  form = new FormGroup({
    nomeViagem: new FormControl('', Validators.required),
  });

  formParadas = new FormGroup({
    index: new FormControl(''),
    cep: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required),
    logradouro: new FormControl(
      { value: '', disabled: true },
      Validators.required
    ),
    numero: new FormControl(''),
    complemento: new FormControl(''),
    bairro: new FormControl({ value: '', disabled: true }, Validators.required),
    cidade: new FormControl({ value: '', disabled: true }, Validators.required),
    estado: new FormControl({ value: '', disabled: true }, Validators.required),
  });

  listaParadas: interfRotas[] = [];

  constructor(
    public config: InsertEditConfig,

    private http: HttpClient,
    private handler: HttpBackend
  ) {
    super(config, { path: environment.api.URI, context: 'rota' });
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new RotasEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      nomeViagem: this.entity.nome,
    });
    this.entity.rotas?.forEach((v) => {
      this.listaParadas.push(v);
    });
  }

  excluirLinha(index: number) {
    Swal.fire({
      title: 'Deseja excluir esse registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar',
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.listaParadas.splice(index, 1);
      }
    });
  }

  open() {
    this.modalParada.nativeElement.click();
    this.formParadas.reset();
    this.formParadas.controls['logradouro']?.disable();
    this.formParadas.controls['bairro']?.disable();
    this.formParadas.controls['cidade']?.disable();
    this.formParadas.controls['estado']?.disable();
  }

  editLinha(data: interfRotas, index: number) {
    this.modalParada.nativeElement.click();
    this.formParadas.patchValue({
      ...data,
      index: index,
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listaParadas, event.previousIndex, event.currentIndex);
  }

  addParada() {
    if (this.formParadas.invalid) {
      this.formParadas.markAllAsTouched();
      return;
    }

    const { index, ...val } = this.formParadas.getRawValue();

    if (val.cep.length == 8) {
      this.formParadas.markAllAsTouched();
      return;
    }

    if (index != undefined) {
      this.listaParadas.splice(index, 1, val);
    } else {
      this.listaParadas.push({
        ...val,
      });
    }

    this.closeModal.nativeElement.click();
    this.formParadas.controls['logradouro']?.disable();
    this.formParadas.controls['bairro']?.disable();
    this.formParadas.controls['cidade']?.disable();
    this.formParadas.controls['estado']?.disable();
  }

  getCep() {
    const val = this.formParadas.value;
    if (val.cep.length == 8) {
      this.http
        .get<retornoViaCep>(`https://viacep.com.br/ws/${val.cep}/json`, {
          headers: { Noaddtoken: 'true' },
        })
        .subscribe((resp) => {
          if (resp) {
            this.formParadas.patchValue({
              ...resp,
              estado: resp.uf,
              cidade: resp.localidade,
            });
            this.formParadas.controls['logradouro']?.disable();
            this.formParadas.controls['bairro']?.disable();
            this.formParadas.controls['cidade']?.disable();
            this.formParadas.controls['estado']?.disable();
          }
          if (!!resp.erro || resp.logradouro == '') {
            Swal.fire({
              title: 'CEP não encontrado',
              text: 'deseja cadastrar manualmente?',
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim',
              icon: 'question',
              showCancelButton: true,
              cancelButtonColor: 'red',
              confirmButtonColor: 'blue',
            }).then((response) => {
              if (response.isConfirmed) {
                this.formParadas.controls['logradouro']?.enable();
                this.formParadas.controls['bairro']?.enable();
                this.formParadas.controls['cidade']?.enable();
                this.formParadas.controls['estado']?.enable();
              }
            });
          }
        });
    }
  }

  saveOfUpdateEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.listaParadas.length <= 0) {
      this.formParadas.clearValidators();
    }

    this.entity = {
      ...this.entity,
      nome: this.form.value.nomeViagem,
      rotas: this.listaParadas.length > 0 ? this.listaParadas : [],
    };

    if (this.listaParadas.length <= 1) {
      Swal.fire({
        title: 'Não é possível cadastrar',
        text: 'Você não pode cadastrar uma rota com menos de duas paradas.',
        icon: 'warning',
        confirmButtonColor: 'blue',
      });
    } else {
      this.http[!!this.entityId ? 'patch' : 'post'](
        environment.api.URI +
          'rota' +
          (!!this.entityId ? '/' + this.entityId : ''),
        this.entity
      ).subscribe(
        (resp) => {
          Swal.fire({
            title: 'Rota cadastrada com sucesso',
            icon: 'success',
            confirmButtonColor: 'blue',
            timer: 4000,
          }).then(() => {
            this.voltar();
          });
        },
        (e) => {
          if (!!e.error.error) {
            Swal.fire({
              title: 'Erro ao cadastrar',
              text: 'Ocorreu um erro ao cadastrar o registro, por favor tente novamente mais tarde.',
              icon: 'error',
              confirmButtonColor: 'blue',
            });
          } else {
            this.http
              .patch(environment.api.URI + `rota/${this.entityId}`, this.entity)
              .subscribe(
                (resp) => {
                  Swal.fire({
                    title: 'Rota editada com sucesso',
                    icon: 'success',
                    confirmButtonColor: 'blue',
                    timer: 4000,
                  }).then(() => {
                    this.voltar();
                  });
                },
                (e) => {
                  if (!!e.error.error) {
                    Swal.fire({
                      title: 'Erro ao editar',
                      text: 'Ocorreu um erro ao editar o registro, por favor tente novamente mais tarde.',
                      icon: 'error',
                      confirmButtonColor: 'blue',
                    });
                  }
                }
              );
          }
        }
      );
    }
  }
}
