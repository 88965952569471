import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/core/services/session/session.service';
import { GenericHttpService } from 'src/app/services/generic-http.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ViagensEntity } from '../../entitys/viagens.entity';
import {
  HttpClient,
  InterfPagination,
  InterfaceCustomActions,
  crudDispatchEvent,
} from '@datagrupo/dg-crud';

export interface lacreData {
  lacresCadastrados: number;
  lacresDisponiveis: number;
  lacresEmTransito: number;
}

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss'],
})
export class DashboardMainComponent implements OnInit {
  @ViewChild('relatorioModal') relatorioModal!: ElementRef;
  @ViewChild('modalClose') modalClose!: ElementRef;

  listViagens: any[] = [];
  lacreData: lacreData | any;

  customActions: InterfaceCustomActions[] = [];

  displayedColumns: string[] = [
    'viagem',
    'serial',
    'inicio',
    'chegada',
    'status',
    'empresas',
    'acoes',
  ];

  public permissao: any;
  public active: 'asc' | 'desc' | undefined;
  sortOrd = false;
  sortInd: string | undefined;

  pagination: InterfPagination = {
    page: 0,
    size: 10,
    sort: 'id,desc',
  };

  data = new ViagensEntity();

  constructor(
    private http: GenericHttpService,
    private router: Router,
    private session: SessionService,
    private httpClient: HttpClient
  ) {
    this.permissao = session.user.permissoes?.find(
      (v) =>
        v.identificador
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase() == 'viagens'
    );
  }

  ngOnInit(): void {
    this.getViagens();
    this.http.get('dashboard/dados').subscribe((resp) => {
      this.lacreData = resp;
    });
  }

  getViagens() {
    this.http
      .get('viagem', {
        params: {
          ...this.pagination,
          id: this.session.user.id,
          dasboard: true,
        },
      })
      .subscribe((resp: any) => {
        this.listViagens = resp.data;
        //  @ts-ignore
        this.pagination = {
          ...resp.page,
          //  @ts-ignore
          page: resp.page.number,
        };
      });
  }

  editar(id: string | number | undefined) {
    this.router.navigate([`/user/viagens/${id}`]);
  }

  openModal(row: ViagensEntity) {
    this.relatorioModal.nativeElement.click();
    this.data = row;
  }

  baixarRelatorio(row: ViagensEntity, assinatura: 'PDF' | 'EXCEL') {
    this.httpClient
      .get(
        environment.api.URI +
          (assinatura == 'PDF'
            ? `viagem/gerarPDF/${row.id}`
            : `viagem/gerarXLSX/${row.id}`),
        { responseType: 'blob' }
      )
      .subscribe((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);

        // Abrir uma nova aba com o URL do download
        const a = document.createElement('a');
        a.href = url;
        a.download =
          assinatura == 'PDF'
            ? `Relatorio ${row.nome}.pdf`
            : `Relatorio ${row.nome}.xlsx`; // Especificar o nome do arquivo desejado
        a.target = '_blank';
        a.click();
        if (blob) {
          this.modalClose.nativeElement.click();
        }
      });
  }
  // baixarRelatorio(row: ViagensEntity) {
  //   this.httpClient.get(environment.api.URI + `viagem/gerarPDF/${row.id}`, {
  //     responseType: 'blob',
  //   }).subscribe((blob: Blob) => {
  //     const url = window.URL.createObjectURL(blob);

  //     // Abrir uma nova aba com o URL do download
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `Relatorio ${row.nome}.pdf`; // Especificar o nome do arquivo desejado
  //     a.target = '_blank';
  //     a.click();
  //   },
  //   )
  // }

  sortOrder(identificador: string) {
    this.sortOrd = !this.sortOrd;
    this.sortInd = identificador;
    if (this.sortOrd == true) {
      return (this.active = 'asc');
    }
    return (this.active = 'desc');
  }

  sort(identificador: string) {
    this.sortOrder(identificador);
    this.http
      .get('viagem', {
        params: {
          ...this.pagination,
          dasboard: true,
          sort: `${identificador},${this.active}`,
        },
      })
      .subscribe((resp) => {
        this.listViagens = resp.data;
      });
  }

  excluir(row: ViagensEntity) {
    Swal.fire({
      title: 'Deseja excluir esse registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar',
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.http.delete(`viagem/${row.id}`).subscribe((resp) => {
          const index = this.listViagens.findIndex((e) => e.id == row.id);

          if (index != -1) {
            this.listViagens.splice(index, 1);
            Swal.fire({
              title: 'Registro deletado com sucesso',
              icon: 'success',
              confirmButtonColor: 'blue',
              timer: 4000,
            });
            this.getViagens();
          }
        });
      }
    });
  }

  formataMask = (val: string) => {
    if (val == 'EM_ANDAMENTO') {
      return (val = 'Em andamento');
    }
    return;
  };

  getData() {
    // @ts-ignore
  }

  comecarViagem(row: ViagensEntity) {
    this.http.post(`viagem/comecar/${row.id}`, {}).subscribe((resp) => {
      row.status.titulo = 'INICIADA';
      crudDispatchEvent('listViagens');
    });
  }

  finalizarViagem(row: ViagensEntity) {
    this.http
      .post(`viagem/terminar/${row.id}`, { id: row.id })
      .subscribe((resp) => {
        row.status.titulo = 'FINALIZADA';
        crudDispatchEvent('listViagens');
      });
  }

  cancelarViagem(row: ViagensEntity) {
    this.http.post(`viagem/cancelar/${row.id}`, {}).subscribe((resp) => {
      row.status.titulo = 'CANCELADA';
      crudDispatchEvent('listViagens');
    });
  }

  changePage(pagination: any) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    };

    this.getViagens();
  }
}
