import { CaminhoesEntity } from "../../entitys/caminhoes.entity";
import { EmpresaEntity } from "../../entitys/empresa.entity";
import { MotoristasEntity } from "../../entitys/motoristas.entity";

export interface lacreInterf {
  id: string,
  imei?: any,
  numeroDeSerie?: any,
  online: boolean,
  p:
  [{
    lt: string,
    lg: string,
    elock: string,
    plock: string,
    tds: string,
    b: string,
    sp: string,
    t: string
    /**
     * GPS Ativo ou Inativo (valid location)
     */
    vloc: boolean
  }],
  isChecked: boolean,
  hasError: boolean,
  truckIcon?: L.Marker<L.DivIcon>,
  travado?: boolean,
  lacrado?: boolean,
  violado?: boolean,
  viagemAtual?: { caminhao: CaminhoesEntity, empresa: EmpresaEntity, motorista: MotoristasEntity, rota: { EnderecoDestino: any, enderecoOrigem: any }, status: any }
}

export type LacreAlarmInfo = {
  /**
   * Lacre violado, "Manilha" aberta -> enviar alerta de violação
   */
  isShacklePulledOut: boolean;
  /**
   * Lacre violado, "Case" aberta -> enviar alerta de violação
   */
  isLockNormalOrTampered: boolean;
  isLowBattery: boolean;
  isMCUAbnormal: boolean;
  isGPSAntennaOpenCircuit: boolean;
  isGPSAntennaShortCircuit: boolean;
  isMotorStuckDuringSeal: boolean;
  isMotorStuckDuringUnseal: boolean;
  isOverSpeed: boolean;
  isParkingTimeout: boolean;
  gnssFauty: boolean;
  mainPowerFauty: boolean;
  /**
   * Travamento incompleto. O lacre está se movimentando enquanto aberto. -> enviar alerta de violação
   */
  isMovingWhileOpen: boolean;
};

export interface LacreAlarmDTO extends LacreEventDTO {
  isAlarm: true;
  info: LacreAlarmInfo;
}


export interface LacreEventDTO {
  id: string;
  imei?: string;
  version: 'V1' | 'V2';
  isAlarm: boolean;
  title: string;
  info?: LacreAlarmInfo;
}
