<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between">
          <h4>Cadastro de motorista</h4>
        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Nome do motorista</label>
                <input name="nome" formControlName="nome" type="text" class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">RG</label>
                <input name="rg" formControlName="rg" type="text" class="form-control" mask="00.000.000-0">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">CPF</label>
                <input name="cpf" formControlName="cpf" type="text" class="form-control" mask="000.000.000-00">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Telefone</label>
                <input name="telefone" formControlName="telefone" type="text" class="form-control"
                  mask="(00)00000-0000">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">CNH</label>
                <input name="cnh" formControlName="cnh" type="text" class="form-control" mask="000000000-00">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Empresas</label>
                <select class="form-select" formControlName="empresa" multiple>
                  <option *ngFor="let emp of empresas" [value]="emp.id">
                    {{emp.nome}}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>