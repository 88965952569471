import { GenericHttpService } from './../../../../services/generic-http.service';
import { EmpresaEntity } from './../../entitys/empresa.entity';
import { FormGroup } from '@angular/forms';
import { SimCardEntity } from './../../entitys/simcard.entity';
import { AbstractInsertEdit, InsertEditConfig } from '@datagrupo/dg-crud';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { EnumList, getEnum } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-simcard-inser-edit',
  templateUrl: './simcard-inser-edit.component.html',
  styleUrls: ['./simcard-inser-edit.component.scss']
})
export class SimcardInserEditComponent extends AbstractInsertEdit<SimCardEntity> implements OnInit {

  empresas: EmpresaEntity[] = [];
  enumsList: EnumList[] = []

  public form = new FormGroup({
    numero: new FormControl('', [Validators.required]),
    operadoraId: new FormControl('', [Validators.required]),
    pin: new FormControl('', [Validators.required]),
    empresa: new FormControl('')
  })

  constructor(
    private service: GenericHttpService,
    public config: InsertEditConfig,
    public route: Router,
    public router: ActivatedRoute
  ) {
    super(config, { path: environment.api.URI, context: 'simcard' })

  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.service.get('empresa').subscribe(resp => {
      this.empresas = resp.data
    })
    this.service.get('lookup').subscribe(resp => {
      this.enumsList = resp.filter((v: any) => v.tipo == 'Operadora')
    })
  }

  initNewEntity(): void {
    this.entity = new SimCardEntity();
  }

  override afterFetchEntity(): void {
    this.form.patchValue({
      ...this.entity,
      empresa: this.entity.empresa?.id
    })
  }

  getEnum(params: string) {
    let index = this.enumsList.findIndex(v => v.tipo != params)
    for (let i = 0; i < this.enumsList.length; i++) {
      this.enumsList.splice(index, 1)
    }
  }

  saveOrUpdateEntity() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { empresa, ...form } = this.form.value;


    this.entity = {
      ...this.entity,
      id: this.entityId,
      ...form,
      empresa: !!empresa ? empresa : undefined
    }


    if (this.entity.id === null) {
      this.service.post('simcard', this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Sim Card cadastrado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    } else {
      this.service.patch(`simcard/${this.entity.id}`, this.entity).subscribe(resp => {
        Swal.fire({
          title: 'Sim Card editado com sucesso',
          icon: 'success',
          confirmButtonColor: 'blue',
          timer: 4000
        }).then(() => {
          this.voltar()
        })
      },
      )
    }


  }

}



