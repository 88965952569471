<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between ">
          <h4>Cadastro de lacre</h4>

        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Número de série</label>
                <input name="numeroDeSerie" formControlName="numeroDeSerie" type="text" mask="000000000000000"
                  class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">IMEI</label>
                <input name="imei" formControlName="imei" type="text" mask="000000-00-000000-0" class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">SimCard</label>
                <select matNativeControl class="form-select" formControlName="simCard">
                  <option value="" disabled>Selecione uma opção</option>
                  <option *ngFor="let item of listSimCard" [value]="item.id">
                    {{item.numero}}
                  </option>
                </select>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Empresas</label>
                <select class="form-select" formControlName="empresa">
                  <option value="" disabled>Selecione uma opção</option>
                  <option value="">Nenhuma</option>
                  <option *ngFor="let emp of empresas" [value]="emp.id">
                    {{emp.nome}}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>