import { GenericHttpService } from "../services/generic-http.service";

export interface EnumList {
  id?: number,
  ativo?: boolean,
  tipo?: string,
  titulo?: string,
  createdAt?: string,
  updatedAt?: string
}
export function getFakeTable(name: string): any[] {
  const data = window.localStorage.getItem(name);
  if (!data || data == 'undefined' || data == 'null') return [];

  return [];
}

export const formataData = (dataString: any) => {
  if (!isNaN(dataString)) {
    return '--'
  }
  var data = new Date(!!dataString ? dataString : '');

  var dia = (data.getDate() + 1).toString(),
    diaF = (dia.length == 1) ? '0' + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length == 1) ? '0' + mes : mes,
    anoF = data.getFullYear();
  return diaF + "/" + mesF + "/" + anoF;
}


export const formataMask = (maskValue?: any, identificador?: 'TELEFONE' | 'CPF' | 'CNPJ' | 'RG' | 'IMEI' | 'CNH') => {
  let val = String(maskValue);
  if (val.length == 14 && identificador == 'CNPJ') {
    val = val.replace(/\D/g, ''); //Remove tudo o que não é dígito
    val = val.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    val = val.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    val = val.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    val = val.replace(/(\d{4})(\d)/, '$1-$2');
    return val;
  }
  if (val.length == 11 && identificador == 'CPF') {
    val = val.replace(/\D/g, ''); //Remove tudo o que não é dígito
    val = val.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    val = val.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    val = val.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return val;
  }
  if (val.length == 11 && identificador == 'TELEFONE') {
    val = val.replace(/\D/g, '')
    val = val.replace(/(\d{2})(\d)/, "($1) $2")
    val = val.replace(/(\d)(\d{4})$/, "$1-$2");
    return val;
  }
  if (val.length == 9 && identificador == 'RG') {
    val = val.replace(/\D/g, "");
    val = val.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
    return val;
  }
  if (val.length == 11 && identificador == 'CNH') {
    val = val.replace(/\D/g, "");
    val = val.replace(/(\d{9})(\d{2})$/, "$1-$2")
    return val;
  }
  if (val.length == 15 && identificador == 'IMEI') {
    val = val.replace(/\D/g, "");
    val = val.replace(/(\d{6})(\d{2})(\d{6})(\d{1})$/, "$1-$2-$3-$4")
  }
  return val
}

export const getEnum = (params: string, array: EnumList[]): any => {
  let index = array.findIndex(v => v.tipo != params)
  for (let i = 0; i < array.length; i++) {
    array.splice(index, 1)
  }
  return array
}


export const compareDates = (currentDate: any, rowDate: any) => {

  rowDate = new Date(rowDate);

  return currentDate > rowDate
}