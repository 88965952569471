export const environment = {
  production: false,
  environmentName: 'homolog',
  endpointSocket: 'https://homolog.sety.tech/api',
  apiUrlMock: 'http://localhost:3000/',
  api: {
    URI: 'https://homolog.sety.tech/api/',
    recaptcha: '6LeXXt0fAAAAAMzc2nVUzhZFLXX_kOGJJCNrFB9i',
  },
};
