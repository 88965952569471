import { MonitoramentoModule } from './monitoramento/monitoramento.module';
import { ViagensModule } from './viagens/viagens.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SimcardModule } from './simcard/simcard.module';
import { MotoristasModule } from './motoristas/motoristas.module';
import { CaminhoesModule } from './caminhoes/caminhoes.module';
import { LacresModule } from './lacres/lacres.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateRoutingModule } from './private-routing.module';
import { EmpresaModule } from './empresa/empresa.module';
import { UsuarioModule } from './usuario/usuario.module';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddTokenInterceptor } from 'src/app/core/interceptos/add-token/add-token.interceptor';
import { RotasModule } from './rotas/rotas.module';
import { PermissoesModule } from './permissoes/permissoes.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CallbackMessageInterceptor } from 'src/app/core/interceptos/callback-message/callback-message.interceptor';
import { ContatosModule } from './contatos/contatos.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    DashboardModule,
    EmpresaModule,
    UsuarioModule,
    LacresModule,
    CaminhoesModule,
    MotoristasModule,
    SimcardModule,
    ViagensModule,
    MonitoramentoModule,
    RotasModule,
    MatSelectModule,
    MatFormFieldModule,
    PermissoesModule,
    ContatosModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CallbackMessageInterceptor,
      multi: true,
    },
  ],
})
export class PrivateModule {}
