import { LacresEntity } from './lacres.entity';
import { EmpresaEntity } from './empresa.entity';
import { MotoristasEntity } from './motoristas.entity';
import { CaminhoesEntity } from './caminhoes.entity';
import { DataServer, AbstractEntity, DgTableColumn, DgFilter_input } from "@datagrupo/dg-crud";
import { environment } from "src/environments/environment";
import { formataData } from 'src/app/helpers/helpers';
import { RotasEntity } from './rotas.entity';

@DataServer({
  path: environment.api.URI,
  context: 'viagem'
})

export class ViagensEntity extends AbstractEntity {

  constructor(
    id?: string | number,
    nome?: string,
    codigo?: string | number,
    dataInicio?: string,
    status?: any,
    caminhao?: CaminhoesEntity,
    motorista?: MotoristasEntity,
    empresa?: EmpresaEntity,
    lacre?: LacresEntity,
    rota?: RotasEntity
  ) {
    super()
    this.id = id;
    this.nome = nome;
    this.codigo = codigo;
    this.dataInicio = dataInicio;
    this.caminhao = caminhao;
    this.motorista = motorista;
    this.empresa = empresa;
    this.lacre = lacre;
    this.status = status;
    this.rota = rota;
  }

  @DgFilter_input('text', { name: 'Nome da viagem' })
  @DgTableColumn({ columnName: 'Nome da viagem', resource: (val: string) => !!val ? val : '--', sort: true })
  public nome: string | undefined;

  @DgFilter_input('number', { name: 'Código' })
  @DgTableColumn({ columnName: 'Código', resource: (val: string | number) => !!val ? val : '--', sort: true })
  public codigo?: string | number | undefined;

  @DgTableColumn({ columnName: 'Nº de Série', resource: (val: LacresEntity) => !!val ? val.numeroDeSerie : '--', sort: true })
  public lacre?: LacresEntity | undefined;

  @DgFilter_input('date', { name: 'Data de início', nameParamFind: 'inicio' })
  @DgTableColumn({ columnName: 'Data de início', resource: (val: string) => !!val ? formataData(val) : formataData(val), sort: true })
  public dataInicio?: string | undefined;

  @DgTableColumn({ columnName: 'Status', resource: (val: any) => !!val ? val.titulo : '--', sort: true })
  public status?: any | undefined;

  @DgTableColumn({
    columnName: 'Ponto de Partida', resource: (val: any, row: any) => {
      return !!row.rota ? row.rota[0].descricao : '--'
    },
    sort: true
  })
  public pontoPartida?: string | undefined

  @DgTableColumn({
    columnName: 'Ponto de Chegada', resource: (val: RotasEntity, row: any) => {
      return !!row.rota ? row.rota[1].descricao : '--'
    },
    sort: true
  })
  public pontoChegada?: string | undefined

  @DgTableColumn({columnName: 'Empresas', resource: (val: ViagensEntity) => {return !!val ? val.nome : '--' },sort:true })
  public empresa?: EmpresaEntity | undefined;

  public motorista?: MotoristasEntity | undefined;
  public caminhao?: CaminhoesEntity | undefined;
  public rota?: RotasEntity | undefined




}
