import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AbstractAddToken } from '@datagrupo/dg-ng-util';
import { TokenService } from '../../services/token.service';
import { environment } from 'src/environments/environment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { SessionService } from '../../services/session/session.service';
import Swal from 'sweetalert2';

@Injectable()
export class AddTokenInterceptor
  extends AbstractAddToken
  implements HttpInterceptor
{
  constructor(private token: TokenService, private session: SessionService) {
    super(token);
    this.listUrlExcepAddToken.push(
      environment.api.URI + 'auth/token',
      environment.api.URI + 'auth/refresh'
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.rootIntercept(req, next);
  }

  pipeRequestRefreshToken(resp: any) {
    return resp.token;
  }
  public override rootIntercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this._token.getToken();

    const urlPesquisaAnonima = window.location.pathname.split('/');
    urlPesquisaAnonima.pop();

    if (request.headers.get('Noaddtoken')) {
      request = request.clone({
        headers: request.headers.delete('Noaddtoken'),
      });
      return next.handle(request);
    }

    if (this.exceptRouter() || this.exceptUrl(request.url)) {
      return next.handle(request);
    }

    if (!request.headers.get(this.name_headerToken)) {
      request = request.clone({
        setHeaders: {
          [this.name_headerToken]: (this.addBearer ? this.bearer : '') + token,
          'ngrok-skip-browser-warning': '69420',
        },
      });
    }

    // @ts-ignore
    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          (error.status === 401 || error.status === 403)
        ) {
          if (!this._token.checkExp()) {
            Swal.fire({
              title: 'Sessão expirada',
              text: 'você será redirecionado ao login',
              icon: 'error',
              confirmButtonColor: 'red',
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.token.clearToken();
                this.session.logout();
              }
            });
          }
        }

        return throwError(error);
      })
    );
  }
}
