import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ViagensEntity } from '../../entitys/viagens.entity';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { InterfaceCustomActions, crudDispatchEvent } from '@datagrupo/dg-crud';
import { SessionService } from 'src/app/core/services/session/session.service';
import { compareDates, formataData } from 'src/app/helpers/helpers';

@Component({
  selector: 'app-viagens-main',
  templateUrl: './viagens-main.component.html',
  styleUrls: ['./viagens-main.component.scss'],
})
export class ViagensMainComponent implements OnInit {
  @ViewChild('relatorioModal') relatorioModal!: ElementRef;
  @ViewChild('modalClose') modalClose!: ElementRef;

  public mapEntity = new ViagensEntity();

  customActions: InterfaceCustomActions[] = [];

  public permissao: any;

  date = new Date();
  status: string = '';
  data = new ViagensEntity();

  constructor(
    public http: HttpClient,
    private route: Router,
    private session: SessionService
  ) {
    this.permissao = session.user.permissoes?.find(
      (v) =>
        v.identificador
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase() == 'viagens'
    );

    const currentDate = new Date();

    if (this.permissao?.read) {
      this.customActions.push(
        {
          name: 'Editar',
          action: !!this.permissao.update
            ? (row: ViagensEntity) => this.navigate(row.id)
            : () => {},
          permission: !!this.permissao.update ? true : false,
        },
        {
          name: 'Excluir',
          action: !!this.permissao.delete
            ? (row: ViagensEntity) => this.delete(row)
            : () => {},
          permission: (row: ViagensEntity) => {
            if (this.permissao.delete == true) {
              if (currentDate > new Date(String(row.dataInicio))) {
                return false;
              }
              return true;
            }
            return false;
          },
        },
        {
          name: 'Começar Viagem',
          action: (row: ViagensEntity) => this.comecarViagem(row),
          permission: (row: ViagensEntity) =>
            row.status?.titulo == 'AGENDADA' && !!this.permissao.acoes,
        },
        {
          name: 'Finalizar Viagem',
          action: (row: ViagensEntity) => this.finalizarViagem(row),
          permission: (row: ViagensEntity) =>
            row.status?.titulo == 'INICIADA' && !!this.permissao.acoes,
        },
        {
          name: 'Cancelar Viagem',
          action: (row: ViagensEntity) => this.cancelarViagem(row),
          permission: (row: ViagensEntity) =>
            row.status?.titulo == 'AGENDADA' && !!this.permissao.acoes,
        }
      );
    }
  }

  ngOnInit(): void {}

  dictionary = (data: any) => {
    return data;
  };

  navigate(index: string | number | undefined) {
    this.route.navigate([`/user/viagens/${index}`]);
  }

  openModal(row: ViagensEntity) {
    this.relatorioModal.nativeElement.click();
    this.data = row;
  }

  baixarRelatorio(row: ViagensEntity, assinatura: 'PDF' | 'EXCEL') {
    this.http
      .get(
        environment.api.URI +
          (assinatura == 'PDF'
            ? `viagem/gerarPDF/${row.id}`
            : `viagem/gerarXLSX/${row.id}`),
        { responseType: 'blob' }
      )
      .subscribe((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);

        // Abrir uma nova aba com o URL do download
        const a = document.createElement('a');
        a.href = url;
        a.download =
          assinatura == 'PDF'
            ? `Relatorio ${row.nome}.pdf`
            : `Relatorio ${row.nome}.xlsx`; // Especificar o nome do arquivo desejado
        a.target = '_blank';
        a.click();
        if (blob) {
          this.modalClose.nativeElement.click();
        }
      });
  }

  comecarViagem(row: ViagensEntity) {
    this.http
      .post(environment.api.URI + `viagem/comecar/${row.id}`, {})
      .subscribe((resp) => {
        row.status.titulo = 'INICIADA';
        crudDispatchEvent('mapEntity');
      });
  }

  finalizarViagem(row: ViagensEntity) {
    this.http
      .post(environment.api.URI + `viagem/terminar/${row.id}`, { id: row.id })
      .subscribe((resp) => {
        row.status.titulo = 'FINALIZADA';
        crudDispatchEvent('mapEntity');
      });
  }

  cancelarViagem(row: ViagensEntity) {
    this.http
      .post(environment.api.URI + `viagem/cancelar/${row.id}`, {})
      .subscribe((resp) => {
        row.status.titulo = 'CANCELADA';
        crudDispatchEvent('mapEntity');
      });
  }

  delete(row: ViagensEntity) {
    Swal.fire({
      title: 'Deseja excluir esse registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'blue',
      cancelButtonText: 'Cancelar',
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.http.delete(`viagem/${row.id}`).subscribe((resp) => {
          crudDispatchEvent('viagens');
        });
      }
    });
  }
}
