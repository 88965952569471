<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header d-inline-flex align-items-center justify-content-between  ">
          <h4>Cadastro de caminhão</h4>

        </div>
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Marca</label>
                <input name="marca" formControlName="marca" type="text" class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Ano de fabricação</label>
                <input name="anoFabricacao" formControlName="anoFabricacao" type="number"
                  oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                  class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Ano do modelo</label>
                <input name="anoModelo" formControlName="anoModelo" type="number"
                  oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                  class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Placa</label>
                <input name="placa" formControlName="placa" type="text" class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label class="form-label">Empresas</label>
                <select class="form-select" formControlName="empresa" multiple>
                  <option *ngFor="let emp of empresas" [value]="emp.id">
                    {{ emp.nome }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <app-footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary me-3" (click)="voltar()">Voltar</button>
            <button class="btn btn-success" (click)="saveOrUpdateEntity()">Salvar</button>
          </div>
        </app-footer>
      </div>
    </div>
  </div>
</div>