<div class="row">
  <div class="col-12">
    <div class="card" style="border: none">
      <div class="card-header headerMonitoramento row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <h4 class="card-title">Nº dos Lacres</h4>
        </div>
      </div>
      <div class="row card-body">
        <div class="col-5">
          <div class="mb-2">
            <div class="lista-lacres">
              <div class="row me-2">
                <div>
                  <table class="crud-table">
                    <thead>
                      <tr>
                        <th>Lacres</th>
                        <th>Empresas</th>
                      </tr>
                    </thead>
                    <tbody class="card-body">
                      <!-- <tr *ngFor="let lacre of newListaLacre"> -->
                      <tr *ngFor="let lacre of (tableLacre.controls.data.dataSource || [])">
                        <td>
                          <div class="row">
                            <div class="col-2">
                              <input type="checkbox" [(ngModel)]="lacre.isChecked" [value]="lacre.imei"
                                class="form-checkbox" name="lacre" [id]="lacre.imei" (change)="getSocket($event)"
                                data-observable="true">
                            </div>
                            <div class="col-10">
                              <label [for]="lacre.imei" class="card-title">{{lacre.numeroDeSerie | mask:
                                '000000-00-000000-0'}}</label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="row">
                            <label class="card-title">{{ !!lacre.empresa?.nome ? lacre.empresa?.nome : '--' }}</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="pt-1 d-flex justify-content-center">
              <dg-paginator dg-dynamic-paginator [dynamicTable]="tableLacre"></dg-paginator>
            </div>
          </div>
        </div>
        <div class="mapArea col-7">
          <div id="map"></div>
        </div>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" #content>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <div class="row">
                <h5>Transportadora</h5>
                <span><strong>Nome:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.empresa?.nome :
                  '--'}}</span>
              </div>
              <div class="row">
                <h5>Motorista</h5>
                <span><strong>Nome:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.motorista?.nome :
                  '--'}}</span>
                <span><strong>CPF:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.motorista?.cpf :
                  '--'}}</span>
                <span><strong>RG:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.motorista?.rg :
                  '--'}}</span>
                <span><strong>Telefone:</strong> {{modalData[0]?.viagemAtual ?
                  modalData[0]?.viagemAtual?.motorista?.telefone
                  : '--'}}</span>
              </div>
              <div class="row">
                <h5>Caminhão</h5>
                <span><strong>Marca:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.caminhao?.marca :
                  '--'}}</span>
                <span><strong>Placa:</strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.caminhao?.placa :
                  '--'}}</span>
                <span><strong>Ano do Modelo:</strong> {{modalData[0]?.viagemAtual ?
                  modalData[0]?.viagemAtual?.caminhao?.anoModelo : '--'}}</span>
                <span><strong>Ano de Fabricação:</strong> {{modalData[0]?.viagemAtual ?
                  modalData[0]?.viagemAtual?.caminhao?.anoFabricacao : '--'}}</span>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12" *ngFor="let d of modalData">
            <div class="row">
              <div class="d-flex justify-content-center">
                <h4>Localização Atual</h4>
              </div>
            </div>
            <div class="row">
              <span><strong>Latidude: </strong>{{modalData.length > 0 ? modalData[0].p[0]?.lt : '--'}}</span>
              <span><strong>Longitude: </strong>{{modalData.length > 0 ? modalData[0].p[0]?.lg : '--'}}</span>
            </div>
            <br>
            <div class="row">
              <span><strong>Velocidade: </strong>{{modalData.length > 0 ? modalData[0].p[0]?.sp : '--'}}</span>
              <span><strong>Bateria: </strong>{{modalData.length > 0 ? modalData[0].p[0]?.b : '--'}}</span>
            </div>
            <br>
            <div class="row">
              <span><strong>Lacre Eletrônico: </strong>{{modalData[0]?.imei ? modalData[0].imei : '--'}}</span>
              <span><strong>Viagem: </strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.status?.tipo :
                '--'}}
              </span>
              <span><strong>Status: </strong> {{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.status?.titulo :
                '--'}} </span>
            </div>
            <div class="row pt-1 ">
              <div class="actionButton">
                <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalEnviarSenha"
                  (click)="assinatura = 'DIGITAL'" (change)="loading = !loading">Abrir
                  Lacre</button>
              </div>
              <div class="actionButton pt-1">
                <button class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalEnviarSenha"
                  (click)="assinatura = 'MANUAL'" (change)="loading = !loading">Abertura
                  Manual</button>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="card">
              <div class="row">
                <h5>Itinerário</h5>
              </div>
              <br>
              <div class="row">
                <h5>Início</h5>
                <span>{{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.rota?.enderecoOrigem?.bairro + ' - ' +
                  modalData[0]?.viagemAtual?.rota?.enderecoOrigem?.estado : '--'}}</span>
              </div>
              <div class="row">
                <h5>Destino Final</h5>
                <span>{{modalData[0]?.viagemAtual ? modalData[0]?.viagemAtual?.rota?.EnderecoDestino?.bairro + ' - ' +
                  modalData[0]?.viagemAtual?.rota?.EnderecoDestino?.estado : '--'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL ENVIAR SENHA -->

<div class="modal fade" id="modalEnviarSenha" #content>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="loading == false">
          <label class="form-label">Insira sua senha</label>
          <input [formControl]="senha" name="senha" type="password" class="form-control"
            (keyup.enter)="focusableSubmit.click()">
          <div class="modal-footer align-items-center justify-content-center">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" (click)="sendPassword(assinatura)"
              #focusableSubmit>Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="!!loading">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="mat-stack">
</div>

<app-footer></app-footer>